import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.article`
	width: 90%;
	max-width: 1170px;
	margin: 0 auto;
`;

const PrivacyTermsWrapper = ({ className, style, children }) => {
	return (
		<Wrapper className={className} style={style}>
			{children}
		</Wrapper>
	);
};

export default PrivacyTermsWrapper;

import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../utils/helpers';

export const ContentWrapper = styled.div`
	margin: 60px 0;
	${mediaQuery.tabletL`
		margin: 40px 0;
	`}
	${mediaQuery.mobileXL`
		margin: 50px 0;
	`}
`;

export const RichTextWrapper = styled.div`
	line-height: 2;
	p {
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.tiny};
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		margin-top: 0;
		margin-bottom: 0;
		strong {
			display: inline-block;
			margin-top: 30px;
			line-height: 27px;
			font-family: ${setFontFamily.bold};
			font-weight: ${setFontWeight.normal};
		}
	}
	h3 {
		margin-top: 30px;
		font-weight: ${setFontWeight.normal};
		font-family: ${setFontFamily.bold};
		margin-bottom: 1px;
		line-height: 27px;
		font-size: ${setFontSize.tinySmall};
	}
	ul {
		margin: 0;
		padding: 0;
		margin-top: 30px;
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.tiny};
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		li {
			list-style-type: none;
		}
	}
	a {
		color: ${setColors.themeBlue};
		text-decoration: none;
	}

	${mediaQuery.laptopXL`
		p{
			font-size: ${setFontSize.mediumTiny};
		}
		h3{
			font-size: ${setFontSize.verySmall};
		}
	`}

	${mediaQuery.tabletL`
		p{
			font-size: ${setFontSize.veryTiny};
		}
		h3{
			font-size: ${setFontSize.tiny};
		}
	`}
`;

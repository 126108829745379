import React from 'react';
import { graphql } from 'gatsby';
import RichTextRenderer from '../components/global/RichTextRenderer';
import Layout from '../components/global/layout/Layout';
import PrivacyTermsWrapper from '../components/global/wrappers/PrivacyTermsWrapper';
import * as S from '../components/privacy-page/styles';
import Header from '../components/global/header/Header';
import SEO from '../components/global/seo';

const privacy = ({ data }) => {
	const { content, title, seo } = data.contentfulPrivacyPage;
	return (
		<Layout>
			<SEO {...seo} />
			<Header title={title} privacy={true} />
			<PrivacyTermsWrapper>
				<S.ContentWrapper>
					<S.RichTextWrapper>
						<RichTextRenderer content={content} />
					</S.RichTextWrapper>
				</S.ContentWrapper>
			</PrivacyTermsWrapper>
		</Layout>
	);
};

export const pageQuery = graphql`
	query($slug: String) {
		contentfulPrivacyPage(slug: { eq: $slug }) {
			content {
				raw
			}
			title
			seo {
				...seo
			}
		}
	}
`;

export default privacy;
